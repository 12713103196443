import React from "react"
import { graphql, useStaticQuery } from "gatsby"
// import { useObserver } from "mobx-react-lite"
// import { useStore } from '@hooks/useStore'
import { FluidImage } from '@atoms/Image'
import Seo from "@atoms/Seo"
import Hero from "@blocks/page/Hero"
import { BreadcrumbList } from "@projects/BreadcrumbList"
import { Section, Title, Paragraph, Column } from "@blocks/page/Section"
import { Table } from "@blocks/page/Table"
import { ContentFooter } from "@projects/ContentFooter"

import css from "@css/projects/page/p-content.module.styl"

export default function Recruit() {
	const q = useStaticQuery(graphql`
        query {
            main01 : file(relativePath: { eq: "pageVision__img__hero@2x.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
			}
			message : file(relativePath: { eq: "pageRecruit__img__01@2x.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1280) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
			}
			recruitData: allMicrocmsRecruit {
				edges {
					node {
						age
						detail
						document
						id
						location
						practice
						recruitId
						requirements
						rehire
						retirement_age
						title
					}
				}
			}
		}
	`)
	const data = q.recruitData.edges
	console.log(data)
	return (
		<>
			<Seo subtitle={'RECRUIT'} />
			<Hero
				image={q.main01}
				label='採用情報'
				title1='Recruit'
				title2=''
				links={[
					{
						label: '求める人材',
						scroll: '#talent'
					}, {
						label: '募集要項',
						scroll: '#jobs'
					}
				]}
			/>
			<div className={css.container}>
				<div className={css.inner}>
					<BreadcrumbList title="採用情報" />
					<Section id="talent">
						<Title title1='Talent' title2='求める人材'/>
						<Column>
							<div>
								<Paragraph>
									<h2>1.正直で素直な人</h2>
									<p>
										私たちは正直さと素直さを大切にしています。なぜなら、そういった人物は仲間として信頼できるからです。
										大泉グループは、常に人に対して真っ直ぐであってほしいと思っています。
										事実と感情を切り分けて相手に自分の意見を素直に伝えられる、コミュニケーションが取れる人と働きたいと思っています。
									</p>
									<h2>2.人のために動ける人</h2>
									<p>
										人の喜び、笑顔、求めていることを先取りして、提供しようと出来る人。
										人のために行動することが自然できる人物はとても素晴らしいです。
										人のために動ける人は、率先してお客様のため、地域のために動いてくれると思っています。
									</p>
									<h2>3.人として成長したい人</h2>
									<p>
										地域の未来をつくっていくには、会社が成長し続けることが必要です。そのためには、社員の成長が不可欠です。
										スキル、人間力も向上したいと思い、行動できる人と働きたいと思っています。
										成長することで、一人ひとりの人生も幸せになっていきます。
										社会、地域のために動きたい人、お待ちしています。
									</p>
								</Paragraph>
							</div>
							<div>
								<figure className={css.image}>
									<FluidImage data={q.message} />
								</figure>
							</div>
						</Column>
					</Section>
					<Section id="jobs">
						<Title title1='Requirements' title2='募集要項' />
						<Paragraph>
							<p>
								詳細は、<a target="_blank" href="https://www.hellowork.careers/%E6%B1%82%E4%BA%BA?q=%E5%A4%A7%E6%B3%89%E5%BB%BA%E8%A8%AD%E6%A0%AA%E5%BC%8F%E4%BC%9A%E7%A4%BE&l=%E9%9D%92%E6%A3%AE%E7%9C%8C">ハローワークの求人票</a>にてご確認下さい。
							</p>
						</Paragraph>
						{ data.map( ({ node },i)=>{
							let {
								age,
								detail,
								document,
								id,
								location,
								practice,
								recruitId,
								requirements,
								rehire,
								retirement_age,
								title,
							} = node
							return(
								<Table title={title} key={i}
									isAccordion={true}
									data={[
										[`仕事内容`, detail ],
										[`勤務地`, location],
										[`免許・資格`, requirements],
										[`実務経験`, practice],
										[`年齢`, age],
										[`定年`, retirement_age],
										[`再雇用`, rehire],
										[`応募書類`, document]
									]}
								/>
							)
						})}
					</Section>
				</div>
			</div>
			<ContentFooter/>
		</>
	)
}
