import React from "react"
// import { graphql, useStaticQuery } from "gatsby"
// import { TransitionLink } from "@atoms/Link"
// import { FluidImage, BackgroundFluidImage } from "@atoms/Image"
import { useEventListener } from "@hooks/useEventListener"
import css from "@css/projects/page/p-table.module.styl"

function Table({ title, data, isAccordion=false})
{
    const listWrapperRef = React.useRef()
    const listHeight = React.useRef()
    const [open, setOpen] = React.useState(isAccordion)
    let [style, setStyle] = React.useState({})
    let [resized, setResized] = React.useState(0)

    const onClick = ()=>{
        if (!isAccordion){
            return
        }
        setOpen(!open)
    }

    React.useEffect(()=>{
        if ( !listHeight.current ){
            listHeight.current = listWrapperRef.current.clientHeight
        }
        console.log(listHeight.current)
        setStyle({
            height: !open ? listHeight.current : '0',
        })
    },[resized])

    React.useEffect(() => {
        setStyle({
            height: !open ? listHeight.current : '0',
        })
    },[open])

    useEventListener('resize',()=>{
        listHeight.current = 0
        setStyle({
            height: 'auto',
        })
        setResized(resized+1)
    })
    return(
        <div className={css.container}>
            <div className={css.inner}>
                <h4 className={css.title}
                    style={{
                        cursor: !isAccordion ? 'auto' : 'pointer',
                        paddingLeft: !isAccordion ? '0.8em' : '2.1em',
                    }}
                    onClick={onClick}
                >
                    {title}
                    { isAccordion &&
                        <span className={`${!open ? css.arrow_close : css.arrow}`}></span>
                    }
                </h4>
                <div className={css.listWrapper} style={style} ref={listWrapperRef}>
                    <div className={css.list}>
                    { data && data.map((row, i)=>{
                        return(
                            <div className={css.item} key={i}>
                                <div className={css.label} dangerouslySetInnerHTML={{ __html: row[0] }}/>
                                <div className={css.body} dangerouslySetInnerHTML={{__html:row[1]}}/>
                            </div>
                        )
                    })}
                    </div>
                </div>
            </div>
        </div>
    )
}
export { Table }
